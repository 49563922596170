import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading_page = _resolveComponent("base-loading-page")!
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_loading_page, { show: _ctx.isLoading }, null, 8, ["show"]),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"])),
      id: "update-form"
    }, [
      _createVNode(_component_base_input, {
        id: "firstname",
        modelValue: _ctx.firstname,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstname) = $event)),
        label: _ctx.t('signIn.firstname')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_base_input, {
        id: "lastname",
        modelValue: _ctx.lastname,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastname) = $event)),
        label: _ctx.t('signIn.lastname')
      }, null, 8, ["modelValue", "label"])
    ], 32)
  ]))
}