import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfb3ea42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_f_icon = _resolveComponent("f-icon")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_modal, {
          show: _ctx.showModal,
          title: _ctx.t('profile.logout.title'),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false))
        }, {
          actions: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_base_button, {
                onClick: _ctx.logout,
                class: "red"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('yes')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_base_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('no')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.t('profile.logout.sure')), 1)
          ]),
          _: 1
        }, 8, ["show", "title"])
      ]),
      _: 1
    }),
    _createVNode(_component_base_card, {
      class: "red",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal = true))
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_f_icon, {
            icon: "sign-out-alt",
            rotation: "180"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.t('profile.logout.title')), 1)
        ])
      ]),
      _: 1
    })
  ], 64))
}