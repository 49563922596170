
import { defineComponent, onBeforeMount, reactive, ref } from "vue";
import Status from "@/components/Driver/Status.vue";
import Avatar from "@/components/Driver/Avatar.vue";
import Main from "@/components/Driver/Main.vue";
import Directions from "@/components/Driver/Directions.vue";
import CarPhotos from "@/components/Driver/CarPhotos.vue";
import Logout from "@/components/Driver/Logout.vue";
import { useStore } from "vuex";
import type { User } from "firebase/auth";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { Status, Avatar, Main, Directions, CarPhotos, Logout },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const user = store.getters.user as User;
    const data = reactive({
      displayName: user.displayName,
      imageUrl: user.photoURL,
      status: "no",
      phone: user.phoneNumber,
      direction: null,
      carPhotos: [],
      carType: "car",
    });
    const loading = ref(true);
    const error = ref(null);

    onBeforeMount(async () => {
      try {
        const fetchedData = await store.dispatch("userData");
        const direction = await store.dispatch(
          "direction",
          fetchedData.direction.path
        );
        data.status = fetchedData.status;
        data.carPhotos = fetchedData.carPhotos;
        data.carType = fetchedData.carType;
        data.direction = direction;
      } catch (err: any) {
        error.value = err.message;
      }

      loading.value = false;
    });

    return { data, loading, error, t };
  },
});
