
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    imageLink: { type: String, default: "" },
    fullname: { type: String, default: "" },
  },
  setup(props) {
    const store = useStore();
    const imgURL = ref(props.imageLink);
    store.watch(
      (_, getters) => getters.avatar,
      (newVal, oldVal) => {
        if (newVal !== oldVal) imgURL.value = newVal;
      }
    );

    const uploadAvatar = async (image: File | null) => {
      if (!image) return;

      const downloadURL = await store.dispatch("upload", {
        file: image,
        avatar: true,
      });

      await store.dispatch("setPhoto", downloadURL);
    };

    return { uploadAvatar, imgURL };
  },
});
