import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05724870"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "error"
}
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading_page = _resolveComponent("base-loading-page")!
  const _component_Status = _resolveComponent("Status")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Main = _resolveComponent("Main")!
  const _component_Directions = _resolveComponent("Directions")!
  const _component_CarPhotos = _resolveComponent("CarPhotos")!
  const _component_Logout = _resolveComponent("Logout")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_base_loading_page, {
        key: 0,
        show: _ctx.loading,
        white: ""
      }, null, 8, ["show"]))
    : (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.t("profileError")), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Status, {
            status: _ctx.data.status
          }, null, 8, ["status"]),
          _createVNode(_component_Avatar, {
            fullname: _ctx.data.displayName,
            imageLink: _ctx.data.imageUrl
          }, null, 8, ["fullname", "imageLink"]),
          _createVNode(_component_Main, {
            fullname: _ctx.data.displayName,
            phone: _ctx.data.phone
          }, null, 8, ["fullname", "phone"]),
          _createVNode(_component_Directions, {
            direction: _ctx.data.direction,
            status: _ctx.data.status,
            type: _ctx.data.carType
          }, null, 8, ["direction", "status", "type"]),
          _createVNode(_component_CarPhotos, {
            photos: _ctx.data.carPhotos,
            status: _ctx.data.status
          }, null, 8, ["photos", "status"]),
          _createVNode(_component_Logout)
        ]))
}