
import { defineComponent, computed, ref, watch } from "vue";

export default defineComponent({
  emits: ["change"],
  setup(_, { emit }) {
    const d = new Date();
    let h: string | number = d.getHours();
    if (h < 10) h = "0" + h;
    else h = h.toString();
    const hour = ref(h);
    const minute = ref('00');
    const time = computed(() => `${hour.value}:${minute.value}`);
    emit("change", time);

    function onHourChange(h: string) {
      if (parseInt(h) > 23) {
        hour.value = "23";
      } else if (parseInt(h) < 0) {
        hour.value = "00";
      }
      hour.value = h;
    }

    function onMinuteChange(m: string) {
      if (parseInt(m) > 59) {
        minute.value = "59"
        onHourChange
      } else if (parseInt(m) < 0) minute.value = "00";

      minute.value = m;
    }

    function incrementHour() {
      let h = parseInt(hour.value);
      h++;
      if (h > 23) h = 0;
      hour.value = h.toString();
    }
    function decrementHour() {
      let h = parseInt(hour.value);
      h--;
      if (h < 0) h = 23;
      hour.value = h.toString();
    }

    function incrementMinute() {
      let m = parseInt(minute.value);
      m += 10;
      if (m > 59) {
        m = 0;
        let h = parseInt(hour.value);
        if (h > 23) h = 0;
        else h++;
        hour.value = h.toString();
      }
      minute.value = m.toString();
    }
    function decrementMinute() {
      let m = parseInt(minute.value);
      m -= 10;
      if (m < 0) {
        m = 50;
        let h = parseInt(hour.value);
        if (!h) h = 23;
        else h--;
        hour.value = h.toString();
      }
      minute.value = m.toString();
    }

    watch(hour, () => {
      let h = parseInt(hour.value);
      if (h < 10) hour.value = "0" + h;
      emit("change", time.value);
    });

    watch(minute, () => {
      let m = parseInt(minute.value);
      if (m < 10) minute.value = "0" + m;
      emit("change", time.value);
    });

    return {
      hour,
      minute,
      time,
      onHourChange,
      onMinuteChange,
      incrementHour,
      decrementHour,
      incrementMinute,
      decrementMinute,
    };
  },
});
