
import Direction from "./Direction.vue";
import AddDirection from "./AddDirection.vue";
import { defineComponent, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  components: { Direction, AddDirection },
  props: {
    direction: { type: Object, requied: true },
    status: { type: String, default: "no" },
    type: { type: String, default: "car" },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const isOpen = ref(false);
    const disabled = ref(props.status !== "yes");
    const addDirection = ref(false);
    const d: Ref<any> = ref(props.direction);
    const noDirection = ref(false)

    function isEmpty(obj: { [key: string]: any }) {
      if (!obj) return true;
      if (typeof obj !== 'object') return;
      return Object.keys(obj).length === 0;
    }

    if (isEmpty(d.value)) noDirection.value = true;

    store.watch(
      (_, getters) => getters.hasDirection,
      (value) => {
        d.value = value
        if (isEmpty(value)) noDirection.value = true;
      }
    );

    return { t, isOpen, disabled, addDirection, d, noDirection };
  },
});
