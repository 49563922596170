
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { createToast } from "mosha-vue-toastify";

export default defineComponent({
  props: {
    photos: { type: Array, default: () => [] },
    status: { type: String, default: "no" },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const isOpen = ref(false);
    const removing = ref([false, false, false, false, false]);
    const disabled = ref(props.status !== "yes");

    const p = ref(props.photos);
    const hasPhoto = computed(() => p.value.length > 0);

    const toggle = () => {
      if (!isOpen.value) {
        for (let i = 0; i < removing.value.length; i++) {
          removing.value[i] = false;
        }
      }
      isOpen.value = !isOpen.value;
    };

    const remove = (i: number, $e: Event) => {
      if (removing.value[i]) {
        const el = $e.target! as HTMLElement;
        if (el.tagName === "IMG") {
          el.remove();
          p.value = p.value.filter((_, index) => index !== i);
          // delete from db
          createToast(t("photoRemoved"), {
            hideProgressBar: true,
            type: "warning",
            showIcon: true,
            timeout: 7000,
            position: "top-center",
          });
          store.dispatch("removePhoto", { index: i, photos: p.value });
        }
      }
      removing.value[i] = !removing.value[i];
    };

    const uploadCarPhotos = async (images: FileList | null) => {
      if (!images) return;
      const urls = [];
      createToast(t("uploadingImages"), {
        hideProgressBar: true,
        type: "info",
        showIcon: true,
        timeout: 7000,
        position: "top-center",
      });

      for (const i of images) {
        urls.push(await store.dispatch("upload", { file: i }));
      }

      store.dispatch("updateUserDoc", { carPhotos: urls });
    };

    return {
      t,
      p,
      toggle,
      hasPhoto,
      isOpen,
      uploadCarPhotos,
      removing,
      remove,
      disabled,
    };
  },
});
