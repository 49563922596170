import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56e207e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info tooltip" }
const _hoisted_2 = { class: "tooltiptext" }
const _hoisted_3 = {
  key: 0,
  class: "details"
}
const _hoisted_4 = {
  key: 0,
  class: "road"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddDirection = _resolveComponent("AddDirection")!
  const _component_f_icon = _resolveComponent("f-icon")!
  const _component_Direction = _resolveComponent("Direction")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createVNode(_component_AddDirection, {
          show: _ctx.addDirection,
          type: _ctx.type,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addDirection = false))
        }, null, 8, ["show", "type"])
      ]),
      _: 1
    }),
    _createVNode(_component_base_card, {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen)),
      class: _normalizeClass({ disabled: _ctx.disabled })
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_f_icon, { icon: "location-arrow" }),
          _createTextVNode(_toDisplayString(_ctx.t("profile.directions.title")) + " ", 1),
          _withDirectives(_createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t("profile.directions.info")), 1),
            _createVNode(_component_f_icon, { icon: "info-circle" })
          ], 512), [
            [_vShow, _ctx.disabled]
          ])
        ]),
        (_ctx.isOpen && !_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (!_ctx.d && _ctx.noDirection)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.t("profile.directions.noDirection")), 1),
                    _createElementVNode("div", {
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.addDirection = true), ["stop"]))
                    }, [
                      _createVNode(_component_f_icon, { icon: "plus" })
                    ])
                  ]))
                : (_openBlock(), _createBlock(_component_Direction, {
                    key: 1,
                    direction: _ctx.d
                  }, null, 8, ["direction"]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}