
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  props: {
    status: { type: String, default: "no" },
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute()
    const router = useRouter();
    const isOpen = ref(false);
    const s = ref(props.status)
    const navigate = () => router.push("/verify");
    const query = route.query.status as string | null;
    if (query === 'pending') s.value = 'pending'

    return { t, isOpen, navigate, s };
  },
});
