
import { defineComponent, onMounted, reactive, Ref, ref, watch } from "vue";
import TimePicker from "@/components/TimePicker.vue";
import DatePicker from "@/components/Datepicker.vue";
import TheDot from "@/components/SVG/TheDot.vue";
import AutoDropdown from '@/components/AutoDropdown.vue';
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useStore } from "vuex";
import { createToast } from "mosha-vue-toastify";

type State = {
  from: string;
  to: string;
  date: any;
  time: string;
  price: string;
  type: string;
  free: boolean;
  fromInvalid: boolean;
  toInvalid: boolean;
};

export default defineComponent({
  components: { TimePicker, DatePicker, AutoDropdown, TheDot },
  props: {
    show: { type: Boolean, default: false },
    type: { type: String, default: "car" },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const store = useStore();
    const allOptions: Ref<any> = ref({});
    const townOptions: Ref<any> = ref([]);
    const price = ref("");
    const state = reactive<State>({
      from: "",
      to: "",
      date: new Date(),
      time: "",
      price: "",
      type: props.type,
      free: false,
      fromInvalid: false,
      toInvalid: false,
    });
    const townsURL = store.getters.towns;

    onMounted(() => fetchResults(true));
    watch(locale, (val) => fetchResults(false, val));

    // Говно код старт
    async function fetchResults(reFetch = false, lang = '') {
      if (reFetch) {
        const res = await fetch(townsURL);
        allOptions.value = await res.json();
      }
      const val = lang === 'kz' ? 'qq-cyr' : locale.value;
      townOptions.value = [];
      for (const key in allOptions.value) {
        const str = { id: key, name: allOptions.value[key][val] };
        townOptions.value.push(str);
      }
    }

    const validateSelection = (
      data: { id: string; name: string },
      fromFrom = true
    ) => {
      if (data) {
        if (fromFrom) {
          state.from = data.id;
          state.fromInvalid = false;
        } else {
          state.to = data.id;
          state.toInvalid = false;
        }
      }
    };
    // Говно код финиш

    const rules = {
      price: { required: helpers.withMessage(t("noPrice"), required) },
    };
    const v$ = useVuelidate(rules, state, { $autoDirty: true });

    watch(price, () => {
      if (price.value === "") return;
      state.price = price.value.replaceAll(",", "");
      price.value = state.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });

    const swap = () => {
      const a = state.from;
      state.from = state.to;
      state.to = a;
    };

    const addRoad = async () => {
      if (state.free) state.price = "0";

      const isValid = await v$.value.$validate();
      if (!state.from) state.fromInvalid = true;
      if (!state.to) state.toInvalid = true;
      const isNotValid = state.fromInvalid && state.toInvalid;
      if (isNotValid || !isValid) return;
    
      store.dispatch("addDirection", state);
      store.dispatch("setDirectionsStatus", {
        from: state.from,
        to: state.to,
        date: { manual: new Date(state.date) },
        time: state.time,
      });

      createToast(t("profile.direction.created"), {
        timeout: 7000,
        type: "success",
        position: "top-center",
        hideProgressBar: true,
        showIcon: true,
      });

      emit("close");
    };

    const scroll = ($event: FocusEvent) => {
      const input = $event.target as HTMLInputElement;
      input.style.marginBottom = "180px";
    };

    return {
      t,
      state,
      v$,
      addRoad,
      price,
      swap,
      townsURL,
      scroll,
      validateSelection,
      townOptions
    };
  },
});
