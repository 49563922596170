<template>
  <svg
    class="dot"
    xmlns="http://www.w3.org/2000/svg"
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
  >
    <circle cx="54" cy="54" r="54" fill="#000" />
    <circle cx="54.5" cy="53.5" r="36.5" fill="#fff" />
  </svg>
</template>