
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { createToast } from "mosha-vue-toastify";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    fullname: { type: String, default: ' ' }
  },
  setup(props) {
    const { t } = useI18n();
    const firstname = ref("");
    const lastname = ref("");
    const fullname = computed(() => `${firstname.value} ${lastname.value}`);
    const isLoading = ref(false);
    const store = useStore();
    const name = props.fullname.split(' ');
    firstname.value = name[0];
    lastname.value = name[1];

    const toast = () =>
      createToast("Awmetli turde saqlandi", {
        showIcon: true,
        type: "success",
        position: "top-center",
        hideProgressBar: true,
      });

    const save = async () => {
      isLoading.value = true;
      await store.dispatch('setName', fullname.value)
      isLoading.value = false;
      toast();
    };

    return { t, save, lastname, firstname, isLoading, name };
  },
});
