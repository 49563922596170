
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { createToast } from "mosha-vue-toastify";

export default defineComponent({
  props: {
    direction: { type: Object, required: true },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const showModal = ref(false);

    const displayDate = computed(() => {
      let d: Date;
      if (!props.direction.date) return;
      if (props.direction.date.manual) {
        d = props.direction.date.manual;
      } else {
        d = new Date(props.direction.date.seconds * 1000);
      }
      return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    });

    const removeDirection = async () => {
      store.dispatch("setDirectionsStatus", {});
      store.dispatch("removeDirection");
      createToast(t("profile.direction.ok"), {
        timeout: 7000,
        type: "danger",
        position: "top-center",
        hideProgressBar: true,
        showIcon: true,
      });
    };

    return { t, showModal, displayDate, removeDirection };
  },
});
