import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, vShow as _vShow, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0395fd98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateProfile = _resolveComponent("UpdateProfile")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_f_icon = _resolveComponent("f-icon")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_modal, {
          title: _ctx.t('profile.main.edit'),
          show: _ctx.editing,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editing = false))
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_base_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editing = false)),
              type: "submit",
              form: "update-form",
              mode: "orange"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("profile.main.save")), 1)
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_UpdateProfile, { fullname: _ctx.fullname }, null, 8, ["fullname"])
          ]),
          _: 1
        }, 8, ["title", "show"])
      ]),
      _: 1
    }),
    _createVNode(_component_base_card, {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_f_icon, { icon: "user" }),
          _createTextVNode(_toDisplayString(_ctx.t("profile.main.title")) + " ", 1),
          _withDirectives(_createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.editing = true), ["stop"]))
          }, [
            _createVNode(_component_f_icon, { icon: "edit" })
          ], 512), [
            [_vShow, _ctx.isOpen]
          ])
        ]),
        _createVNode(_Transition, { name: "details" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.t("profile.main.fullname")) + ": ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.fullname), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.t("profile.main.phone")) + ": ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.phone), 1)
              ])
            ], 512), [
              [_vShow, _ctx.isOpen]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}