import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ed8bf02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-card" }
const _hoisted_2 = { class: "ava" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = { for: "test" }
const _hoisted_6 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon = _resolveComponent("f-icon")!
  const _component_base_file_input = _resolveComponent("base-file-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.imgURL,
          alt: _ctx.fullname,
          class: "avatar"
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, [
            _createVNode(_component_f_icon, { icon: "camera" })
          ]),
          _createVNode(_component_base_file_input, {
            id: "test",
            class: "input-file",
            onFile: _ctx.uploadAvatar
          }, null, 8, ["onFile"])
        ])
      ])
    ]),
    _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.fullname), 1)
  ], 64))
}