import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ddbae8b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "details" }
const _hoisted_2 = {
  key: 0,
  class: "road"
}
const _hoisted_3 = { for: "test-1" }
const _hoisted_4 = { class: "center" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon = _resolveComponent("f-icon")!
  const _component_base_file_input = _resolveComponent("base-file-input")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    onClick: _ctx.toggle,
    class: _normalizeClass({ disabled: _ctx.disabled })
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, [
        _createVNode(_component_f_icon, { icon: "image" }),
        _createTextVNode(_toDisplayString(_ctx.t("profile.carPhotos.title")), 1)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        (!_ctx.hasPhoto)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.t("profile.carPhotos.noPhoto")), 1),
              _createElementVNode("label", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_f_icon, { icon: "plus" })
                ]),
                _createVNode(_component_base_file_input, {
                  id: "test-1",
                  class: "input-file",
                  onFiles: _ctx.uploadCarPhotos,
                  multiple: ""
                }, null, 8, ["onFiles"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photos, (src, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(['image', { over: _ctx.removing[i] }]),
                  key: src,
                  onClick: _withModifiers(($event: any) => (_ctx.remove(i, $event)), ["stop"])
                }, [
                  _createElementVNode("img", {
                    src: src,
                    alt: `your car${i}`
                  }, null, 8, _hoisted_7),
                  _withDirectives(_createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_f_icon, { icon: "trash-alt" })
                    ]),
                    _createElementVNode("span", null, _toDisplayString(_ctx.t("remove")), 1)
                  ], 512), [
                    [_vShow, _ctx.removing[i]]
                  ])
                ], 10, _hoisted_6))
              }), 128))
            ]))
      ], 512), [
        [_vShow, _ctx.isOpen && !_ctx.disabled]
      ])
    ]),
    _: 1
  }, 8, ["onClick", "class"]))
}