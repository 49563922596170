
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const {t} = useI18n()
    const store = useStore();
    const showModal = ref(false);
    const logout = () =>
      store
        .dispatch("logout")
        .then(() => (window.location.href = window.location.origin));

    return { t, logout, showModal };
  },
});
