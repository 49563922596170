import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac35f9cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions" }
const _hoisted_2 = { class: "from-to" }
const _hoisted_3 = { class: "w" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "icon" }
const _hoisted_6 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_f_icon = _resolveComponent("f-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_modal, {
          show: _ctx.showModal,
          title: _ctx.t('removeDirection'),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false))
        }, {
          actions: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_base_button, { onClick: _ctx.removeDirection }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("yes")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_base_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("no")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.t("profile.direction.sure")), 1)
          ]),
          _: 1
        }, 8, ["show", "title"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.direction.from), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_f_icon, { icon: "exchange-alt" })
      ]),
      _createElementVNode("span", null, _toDisplayString(_ctx.direction.to), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, [
        _createVNode(_component_f_icon, { icon: "calendar-alt" })
      ]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("date")) + ":", 1),
      _createTextVNode(" " + _toDisplayString(_ctx.displayDate) + " " + _toDisplayString(_ctx.direction.time), 1)
    ]),
    _createElementVNode("div", {
      class: "road",
      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.showModal = true), ["stop"]))
    }, [
      _createElementVNode("div", null, [
        _createVNode(_component_f_icon, { icon: "trash-alt" })
      ])
    ])
  ], 64))
}