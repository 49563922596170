
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import UpdateProfile from "./UpdateProfile.vue";

export default defineComponent({
  components: { UpdateProfile },
  props: {
    fullname: { type: String, default: "" },
    phone: { type: String, default: "" },
  },
  setup() {
    const { t } = useI18n();
    const isOpen = ref(false);
    const editing = ref(false);

    return { t, isOpen, editing };
  },
});
