import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30f12895"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = { class: "details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon = _resolveComponent("f-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_ctx.s === 'no')
    ? (_openBlock(), _createBlock(_component_base_card, {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            _createVNode(_component_f_icon, {
              icon: "exclamation-triangle",
              style: {"color":"red"}
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.t('profile.status.no')) + " ", 1),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_f_icon, { icon: "chevron-down" })
            ])
          ]),
          _createVNode(_Transition, { name: "details" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(_ctx.t('profile.status.text')), 1),
                _createVNode(_component_base_button, {
                  mode: "orange",
                  onClick: _withModifiers(_ctx.navigate, ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('profile.status.btn')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ], 512), [
                [_vShow, _ctx.isOpen]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_ctx.s === 'pending')
      ? (_openBlock(), _createBlock(_component_base_card, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, [
              _createVNode(_component_f_icon, {
                icon: "hourglass-start",
                style: {"color":"#e49b0f"}
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.t('profile.status.pending')), 1)
            ])
          ]),
          _: 1
        }))
      : (_ctx.s === 'yes')
        ? (_openBlock(), _createBlock(_component_base_card, { key: 2 }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, [
                _createVNode(_component_f_icon, {
                  icon: "user-check",
                  style: {"color":"#28a745"}
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.t('profile.status.yes')), 1)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
}